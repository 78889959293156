<template>
  <div class="element">
    <x-table
      :columns="table.columns"
      :data="table.data"
      :loading="table.loading"
      :config="config"
      @add="add"
      @search="search"
      @page-change="pageChange"
      @page-size-change="pageSizeChange"
    ></x-table>

    <Modal
      v-model="modal.show"
      :title="modal.title"
      @on-visible-change="modalShow"
    >
      <Form ref="form" :model="form" :rules="rules" :label-width="100">
        <FormItem label="元素名称" prop="elementName">
          <Input v-model="form.elementName"></Input>
        </FormItem>
        <FormItem label="元素符号" prop="elementChemicalName">
          <Input v-model="form.elementChemicalName"></Input>
        </FormItem>
        <FormItem label="简介" prop="content">
          <Input v-model="form.content"></Input>
        </FormItem>
      </Form>
      <p slot="footer" style="text-align: center">
        <Button type="primary" :loading="modal.submitLoading" @click="submit"
          >提交</Button
        >
        <Button @click="() => (modal.show = false)">取消</Button>
      </p>
    </Modal>
  </div>
</template>

<script>
export default {
  name: "",
  data() {
    return {
      table: {
        columns: [
          {
            title: "元素名称",
            minWidth: 100,
            key: "elementName",
          },
          {
            title: "元素符号",
            minWidth: 100,
            key: "elementChemicalName",
          },
          {
            title: "简介",
            minWidth: 100,
            key: "content",
          },
          {
            title: "操作",
            width: 100,
            render: (h, { row }) => {
              return (
                <div>
                  <a onClick={() => this.edit(row)}>编辑</a>
                </div>
              );
            },
          },
        ],
        data: [],
        loading: false,
      },
      page: {
        pageNo: 1,
        pageSize: 10,
        total: 0,
      },
      search_data: {},
      modal: {
        show: false,
        title: "",
        submitLoading: false,
      },
      form: {
        id: "",
        elementName: "",
        elementChemicalName: "",
        content: "",
      },
      rules: {
        elementName: [{ required: true, message: "请填写元素名称" }],
        elementChemicalName: [{ required: true, message: "请填写元素符号" }],
      },
    };
  },
  computed: {
    config() {
      let config = {
        filter: {
          add: {
            addBtnName: "新增元素",
          },
          width: 200,
          filterBox: [
            {
              conditionName: "元素名称",
              component: "input",
              field: "elementName",
              defaultValue: "",
            },
          ],
        },
        page: this.page,
      };
      return config;
    },
  },
  methods: {
    add() {
      this.modal = {
        show: true,
        title: "新增元素",
        submitLoading: false,
      };
    },

    search(data) {
      this.page.pageNo = 1;
      this.search_data = data;
      this.getList();
    },

    pageChange(pageNo) {
      this.page.pageNo = pageNo;
      this.getList();
    },
    pageSizeChange(pageSize) {
      this.page.pageSize = pageSize
      this.getList()
    },

    getList() {
      this.table.loading = true;
      this.$post(this.$api.ELEMENT.LIST, {
        pageNo: this.page.pageNo,
        pageSize: this.page.pageSize,
        ...this.search_data,
      })
        .then((res) => {
          this.table.data = res.list;
          this.page.total = +res.total;
        })
        .finally(() => {
          this.table.loading = false;
        });
    },

    edit(row) {
      for (let key in this.form) {
        this.form[key] = row[key];
      }
      this.modal = {
        show: true,
        title: "",
        submitLoading: false,
      };
    },

    modalShow(visible) {
      if (visible) return;
      this.form = {
        id: "",
        elementName: "",
        elementChemicalName: "",
        content: "",
      };
      this.$refs.form.resetFields();
    },
    submit() {
      this.$refs.form.validate().then((res) => {
        if (!res) return;
        this.modal.submitLoading = true;
        this.$post(this.$api.ELEMENT.ADD, {
          ...this.form,
        })
          .then(() => {
            this.getList();
            this.modal.show = false;
          })
          .finally(() => {
            this.modal.submitLoading = false;
          });
      });
    },
  },
  mounted() {
    this.getList();
  },
};
</script>

<style lang="less" scoped>
.element {
  width: 100%;
  height: 100%;
}
</style>